import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router/'
import './website/css/style.scss'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueExcelXlsx from "vue-excel-xlsx";
import WebCam from 'vue-web-cam'
import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import GAuth from 'vue-google-oauth2'
import VueGoogleApi from 'vue-google-api'
import {PhotoCapture, VideoCapture} from 'vue-media-recorder'

// const gauthOption = {
//   clientId: '73555754893-57bgs5njnkrl68dji5p12fv4gibfj6ce.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'select_account'
// }

 

const config = {
  apiKey: 'AIzaSyBjMORzCCSfs7tN2lu4FP7yR67NrH9eJYw',
  clientId: '73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com',
  scope: 'https://www.googleapis.com/auth/drive/file',
  discoveryDocs: [ 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
}
Vue.use(VueGoogleApi, config)

Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(VueExcelXlsx);
Vue.use(WebCam)
Vue.use(Datetime)
// Vue.use(GAuth, gauthOption)
Vue.use(PhotoCapture)
Vue.use(VideoCapture)
 
new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
