export default (to, from, next) => {
  if (localStorage.getItem('Branch_BranchID') != null) {
    // verify with firebase or jwt
    next()
  } else {
    localStorage.removeItem('Branch_BranchID')
    localStorage.removeItem('Branch_StaffID')
    localStorage.setItem('location_href_tmp', window.location.href)
    next('/login')

  }
}
